import React, { useState, useCallback } from "react";
import { Header, Grid, Message } from "semantic-ui-react";
// import hsk1Words from '../HSKOcr/hsk1.json';
// import hsk2Words from '../HSKOcr/hsk2.json';
// import hsk3Words from '../HSKOcr/hsk3.json';
// import hsk4Words from '../HSKOcr/hsk4.json';
// import hsk5Words from '../HSKOcr/hsk5.json';
// import hsk6Words from '../HSKOcr/hsk6.json';
import { MainWrapper } from "../HSKOcr/HskOcrSC";
import { SnippetsOutlined, SaveOutlined } from '@ant-design/icons';
import { Dropdown, Menu, Button } from "antd";

const SavedWordsStack = React.memo(({ savedWords, handleRestoreWord }) => {
  console.log('SavedWordsStack rendering');
  
  const handleMouseOver = useCallback((e) => {
    e.currentTarget.style.transform = 'translateX(50%) scale(1.1)';
  }, []);

  const handleMouseOut = useCallback((e) => {
    e.currentTarget.style.transform = 'translateX(50%)';
  }, []);
  
  if (savedWords.length === 0) return null;
  
  return (
    <div style={{
      position: 'absolute',
      right: '15px',
      top: '20px',
      display: 'flex',
      flexDirection: 'column',
      gap: '15px',
      padding: '0',
      width: '35px',
      zIndex: '999',
      willChange: 'transform',
      transform: 'translateZ(0)',
      maxHeight: 'calc(100vh - 40px)',
      overflowY: 'auto',
      overscrollBehavior: 'contain',
      scrollbarWidth: 'none',
      touchAction: 'pan-y',
      pointerEvents: 'auto',
      WebkitOverflowScrolling: 'touch',
      '&::-webkit-scrollbar': {
        display: 'none'
      },
      '@media (max-width: 768px)': {
        position: 'absolute',
        right: '15px',
        top: '20px'
      }
    }}>
      {savedWords.map((word, index) => (
        <div
          key={`saved-${word.hanzi}-${index}`}
          onClick={() => handleRestoreWord(word)}
          style={{
            width: word.hanzi.length > 1 ? '50px' : '40px',
            height: word.hanzi.length > 1 ? '60px' : '40px',
            marginTop: '1rem',
            borderRadius: '50%',
            backgroundColor: word.color,
            color: 'white',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
            fontSize: word.hanzi.length > 2 ? '0.9rem' : '1.2rem',
            boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
            transition: 'transform 0.2s ease',
            margin: '5px 0',
            '@media (max-width: 768px)': {
              width: word.hanzi.length > 1 ? '60px' : '32px',
              height: word.hanzi.length > 1 ? '60px' : '32px',
              fontSize: word.hanzi.length > 2 ? '0.7rem' : '0.9rem',
              margin: '0',
              transform: 'translateX(50%)',
              willChange: 'transform',
              backfaceVisibility: 'hidden'
            }
          }}
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
        >
          {word.hanzi}
        </div>
      ))}
    </div>
  );
}, (prevProps, nextProps) => {
  return prevProps.savedWords.length === nextProps.savedWords.length &&
         prevProps.savedWords.every((word, index) => word.hanzi === nextProps.savedWords[index].hanzi);
});

function HskLookup() {
  const [textInput, setTextInput] = useState("");
  const [matchedWords, setMatchedWords] = useState([]);
  const [savedWords, setSavedWords] = useState([]);
  const [animatingWord, setAnimatingWord] = useState(null);
  const [selectedHSKVersion, setSelectedHSKVersion] = useState("2.0");

  const handleMenuClick = ({ key }) => {
    setSelectedHSKVersion(key);
  };

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="2.0">HSK 2.0</Menu.Item>
      <Menu.Item key="3.0">HSK 3.0</Menu.Item>
    </Menu>
  );

  const findHSKMatches = (text) => {
    if (selectedHSKVersion === "3.0") {
      return [];
    }

    const matches = [];
    
    // Create a single array of all words with their HSK level
    const allHskWords = [
      // ...hsk1Words.words.map(word => ({ ...word, level: 1, color: '#FF8A8A', bgColor: '#FFF1F1', borderColor: '#FFD1D1' })),
      // ...hsk2Words.words.map(word => ({ ...word, level: 2, color: '#4CAF50', bgColor: '#F1F8E9', borderColor: '#C5E1A5' })),
      // ...hsk3Words.words.map(word => ({ ...word, level: 3, color: '#3F51B5', bgColor: '#E8EAF6', borderColor: '#C5CAE9' })),
      // ...hsk4Words.words.map(word => ({ ...word, level: 4, color: '#FF9800', bgColor: '#FFF3E0', borderColor: '#FFE0B2' })),
      // ...hsk5Words.words.map(word => ({ ...word, level: 5, color: '#9C27B0', bgColor: '#F3E5F5', borderColor: '#E1BEE7' })),
      // ...hsk6Words.words.map(word => ({ ...word, level: 6, color: '#00BCD4', bgColor: '#E0F7FA', borderColor: '#B2EBF2' }))
    ];

    // Create maps for faster lookup
    const wordMap = new Map();
    const uniqueMatches = new Map();
    
    // Organize words by length and create lookup maps
    allHskWords.forEach(word => {
      const existing = wordMap.get(word.hanzi) || [];
      wordMap.set(word.hanzi, [...existing, word]);
    });

    let remainingText = text;
    let position = 0;

    while (remainingText.length > 0) {
      let matched = false;
      
      // Try longer matches first (up to 4 characters)
      for (let len = 4; len > 0; len--) {
        const chunk = remainingText.slice(0, len);
        const matches = wordMap.get(chunk);
        
        if (matches) {
          // Get the lowest HSK level match
          const bestMatch = matches.sort((a, b) => a.level - b.level)[0];
          
          // Add to unique matches
          if (!uniqueMatches.has(chunk)) {
            uniqueMatches.set(chunk, {
              ...bestMatch,
              position,
              length: chunk.length,
              allMatches: matches
            });
          }
          
          remainingText = remainingText.slice(len);
          position += len;
          matched = true;
          break;
        }
      }
      
      if (!matched) {
        remainingText = remainingText.slice(1);
        position += 1;
      }
    }

    return Array.from(uniqueMatches.values()).sort((a, b) => a.position - b.position);
  };

  const handleTextChange = (e) => {
    const value = e.target.value;
    setTextInput(value);
    const matches = findHSKMatches(value);
    setMatchedWords(matches);
  };

  const handlePaste = async () => {
    try {
      const text = await navigator.clipboard.readText();
      setTextInput(text);
      const matches = findHSKMatches(text);
      setMatchedWords(matches);
    } catch (err) {
      //console.error('Failed to read clipboard contents: ', err);
    }
  };

  const handleSaveWord = (word) => {
    setAnimatingWord(word);
    
    setTimeout(() => {
      setSavedWords(prev => [...prev, word]);
      setAnimatingWord(null);
    }, 0);
  };

  const handleRestoreWord = useCallback((word) => {
    setSavedWords(prev => prev.filter(w => w.hanzi !== word.hanzi));
    
    if (!textInput.includes(word.hanzi)) {
      const newText = textInput ? `${textInput} ${word.hanzi}` : word.hanzi;
      setTextInput(newText);
      setMatchedWords(findHSKMatches(newText));
    }
  }, [textInput]);

  return (
    <MainWrapper>
      <Grid divided style={{ 
        width: "95%",
        margin: "0 auto",
        maxWidth: "2000px",
      }}>
        <div style={{ 
          display: 'flex', 
          justifyContent: 'flex-end',
          marginRight: '15px',
          marginBottom: '12px',
          marginTop: '-5px',
          '@media (max-width: 768px)': {
            marginTop: '10px',
            marginBottom: '8px',
            marginRight: '8px'
          }
        }}>
          <Dropdown
            overlay={menu}
            placement="bottomRight"
            trigger={["click"]}
            arrow
          >
            <Button>HSK {selectedHSKVersion}</Button>
          </Dropdown>
        </div>

        <div className="divider" style={{
          display: "flex",
          width: "100%",
          gap: "40px",
          '@media (max-width: 768px)': {
            flexDirection: "column",
            height: "100%",
            gap: "8px"
          }
        }}>
          <Grid.Column style={{ width: "100%" }} key={0}>
            <div className="text-container" style={{
              padding: "20px",
              width: "100%",
              margin: "0 auto",
              position: "relative",
              '@media (max-width: 768px)': {
                padding: "10px",
                margin: "0"
              }
            }}>
              <div style={{
                position: "absolute",
                top: "30px",
                right: "30px",
                zIndex: "1",
                '@media (max-width: 768px)': {
                  top: "20px",
                  right: "20px",
                }
              }}>
                <button
                  onClick={handlePaste}
                  style={{
                    background: "rgba(255, 255, 255, 0.05)",
                    border: "none",
                    borderRadius: "8px",
                    padding: "10px",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    transition: "all 0.2s ease",
                  }}
                  onMouseOver={(e) => {
                    e.currentTarget.style.transform = "translateY(-2px)";
                    e.currentTarget.style.background = "rgba(255, 255, 255, 0.08)";
                  }}
                  onMouseOut={(e) => {
                    e.currentTarget.style.transform = "translateY(0)";
                    e.currentTarget.style.background = "rgba(255, 255, 255, 0.05)";
                  }}
                >
                  <SnippetsOutlined style={{ fontSize: '24px', color: 'rgba(255, 255, 255, 0.5)' }} />
                </button>
              </div>
              <textarea
                value={textInput}
                onChange={handleTextChange}
                placeholder="Enter Chinese text here..."
                maxLength={1000}
                style={{
                  width: "100%",
                  minHeight: "400px",
                  marginTop: "-0.70rem",
                  padding: "30px",
                  fontSize: "28px",
                  lineHeight: "1.8",
                  color: "white",
                  backgroundColor: "rgba(255, 255, 255, 0.05)",
                  border: "none",
                  borderRadius: "12px",
                  resize: "vertical",
                  transition: "all 0.3s ease",
                  outline: "none",
                  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                  '@media (max-width: 768px)': {
                    height: "100%",
                    fontSize: "18px",
                    padding: "15px"
                  }
                }}
                onFocus={(e) => {
                  e.target.style.background = "rgba(255, 255, 255, 0.08)";
                }}
                onBlur={(e) => {
                  e.target.style.background = "rgba(255, 255, 255, 0.05)";
                }}
              />
              <div style={{
                position: "absolute",
                bottom: "30px",
                right: "30px",
                fontSize: "0.9rem",
                color: "rgba(255, 255, 255, 0.5)",
                padding: "6px 12px",
                borderRadius: "8px",
                '@media (max-width: 768px)': {
                  bottom: "20px",
                  right: "20px",
                }
              }}>
                {textInput.length}/1000
              </div>
            </div>
          </Grid.Column>

          <Grid.Column style={{ 
            width: "95%",
            position: "relative",
          }} key={1}>
            {selectedHSKVersion === "3.0" && (
              <Message
                style={{
                  background: 'rgba(255, 255, 255, 0.05)',
                  border: 'none',
                  color: 'white',
                  margin: '0 15px 20px 15px',
                  padding: '15px',
                  textAlign: 'center'
                }}
                content="HSK 3.0 word matching is coming soon!"
              />
            )}

            <SavedWordsStack savedWords={savedWords} handleRestoreWord={handleRestoreWord} />

            <div className="result-container" style={{
              transition: 'all 0.3s ease',
              width: '100%',
              '@media (max-width: 768px)': {
                height: "100%",
                margin: 0,
                padding: "15px 45px 15px 15px",
                overflow: "auto",
                WebkitOverflowScrolling: "touch"
              }
            }}>
              <Message
                size="massive"
                content={
                  <div style={{
                    paddingRight: savedWords.length > 0 ? '40px' : '0'
                  }}>
                    <div className="original-text" style={{ 
                      fontSize: savedWords.length > 0 ? '1.3rem' : '1.5rem', 
                      marginBottom: '1.5rem',
                      lineHeight: '1.8',
                      color: 'white',
                      transition: 'all 0.3s ease'
                    }}>
                      {textInput || "欢迎"}
                    </div>
                    <div style={{ 
                      marginTop: '1.5rem',
                      display: 'flex',
                      flexWrap: 'wrap',
                      gap: '0.8rem',
                      '@media (max-width: 768px)': {
                        marginTop: '1rem',
                        gap: '0.5rem'
                      }
                    }}>
                      {matchedWords.map((word, index) => {
                        const isSaved = savedWords.some(w => w.hanzi === word.hanzi);
                        const isAnimating = animatingWord?.hanzi === word.hanzi;
                        
                        return (
                          <div
                            key={index}
                            style={{
                              backgroundColor: word.bgColor,
                              color: word.color,
                              padding: '0.8rem 1.2rem',
                              margin: '0.2rem',
                              borderRadius: '8px',
                              display: isSaved ? 'none' : 'inline-block',
                              border: `1px solid ${word.borderColor}`,
                              boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
                              fontSize: '1rem',
                              lineHeight: '1.5',
                              cursor: 'pointer',
                              position: 'relative',
                              minWidth: '200px',
                              opacity: isAnimating ? 0 : 1,
                              transform: isAnimating ? 'translateX(20px)' : 'translateX(0)',
                              // transition: 'opacity 0.2s ease, transform 0.2s ease',
                              pointerEvents: isSaved || isAnimating ? 'none' : 'auto'
                            }}
                          >
                            <div style={{ fontWeight: '600', marginBottom: '0.3rem' }}>
                              {word.hanzi} {word.part_of_speech && 
                                <span style={{ 
                                  fontSize: '0.8rem', 
                                  opacity: 0.7,
                                  fontWeight: 'normal' 
                                }}>
                                  ({word.part_of_speech})
                                </span>
                              }
                            </div>
                            <div style={{ 
                              fontSize: '0.9rem', 
                              color: '#666',
                              marginBottom: '0.2rem' 
                            }}>
                              {word.pinyin}
                            </div>
                            <div style={{ 
                              fontSize: '0.9rem',
                              color: '#444',
                              marginBottom: word.example_sentence ? '0.8rem' : '0'
                            }}>
                              {word.english}
                            </div>
                            {word.example_sentence && (
                              <div style={{
                                borderTop: '1px solid rgba(0,0,0,0.1)',
                                paddingTop: '0.8rem',
                                marginTop: '0.4rem'
                              }}>
                                <div style={{ 
                                  fontSize: '0.85rem',
                                  color: '#444',
                                  marginBottom: '0.2rem'
                                }}>
                                  {word.example_sentence.chinese}
                                </div>
                                <div style={{ 
                                  fontSize: '0.8rem',
                                  color: '#666',
                                  marginBottom: '0.2rem'
                                }}>
                                  {word.example_sentence.pinyin}
                                </div>
                                <div style={{ 
                                  fontSize: '0.8rem',
                                  color: '#666',
                                  fontStyle: 'italic'
                                }}>
                                  {word.example_sentence.english}
                                </div>
                              </div>
                            )}
                            <div style={{ 
                              position: 'absolute',
                              top: '-8px',
                              right: '-8px',
                              backgroundColor: word.color,
                              color: 'white',
                              borderRadius: '50%',
                              width: '24px',
                              height: '24px',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              fontSize: '0.8rem',
                              fontWeight: 'bold'
                            }}>
                              {word.level}
                            </div>
                            {!savedWords.some(w => w.hanzi === word.hanzi) && (
                              <div
                                onClick={() => handleSaveWord(word)}
                                style={{
                                  position: 'absolute',
                                  top: '10px',
                                  right: '20px',
                                  cursor: 'pointer',
                                  opacity: 0.7,
                                  transition: 'opacity 0.2s ease',
                                }}
                                onMouseOver={(e) => {
                                  e.currentTarget.style.opacity = 1;
                                }}
                                onMouseOut={(e) => {
                                  e.currentTarget.style.opacity = 0.7;
                                }}
                              >
                                <SaveOutlined style={{ fontSize: '20px' }} />
                              </div>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                }
                style={{ 
                  margin: 15, 
                  padding: savedWords.length > 0 ? '1.2rem' : '1.5rem',
                  position: 'relative',
                  '@media (max-width: 768px)': {
                    margin: '0',
                    padding: '1rem',
                    borderRadius: '8px',
                    height: '100%',
                    overflow: 'auto'
                  }
                }}
              />
            </div>
          </Grid.Column>
        </div>
      </Grid>
    </MainWrapper>
  );
}

const styles = `
  @keyframes moveToSaved {
    0% {
      transform: translate(0, 0) scale(1);
      opacity: 1;
      height: auto;
    }
    100% {
      transform: translate(200px, 0) scale(0.5);
      opacity: 0;
      height: 0;
    }
  }
  
  @keyframes slideUp {
    0% {
      transform: translateY(20px);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
  
  @media (max-width: 768px) {
    @keyframes moveToSaved {
      0% {
        transform: translate(0, 0) scale(1);
        opacity: 1;
        height: auto;
      }
      100% {
        transform: translate(100px, 0) scale(0.5);
        opacity: 0;
        height: 0;
      }
    }
  }
`;

const styleSheet = document.createElement("style");
styleSheet.innerText = styles;
document.head.appendChild(styleSheet);

export default HskLookup;
import { useState, useEffect, useContext } from "react";
import { MenuOutlined, CloseOutlined } from "@ant-design/icons";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import "./Navbar.css";
import { DropdownOptions } from "../DropdownOptions/DropdownOptions";
// import { Input } from "antd";
import { actions, GlobalContext } from "../../App";
import Logo from "../Logo/Logo";

const Navbar = ({ user, setUser }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const { dispatch } = useContext(GlobalContext);

  useEffect(() => {
    if (!user) {
      const sessionUser = JSON.parse(window.sessionStorage.getItem("currentUser"));
      if (sessionUser) {
        setUser(sessionUser);
        dispatch({
          type: actions.SET_USER,
          payload: { user: sessionUser._doc },
        });
      }
    }
  }, [user, setUser, dispatch]);

  const handleToggle = () => setMenuOpen((prev) => !prev);
  const closeMenu = () => setMenuOpen(false);

  const currentUser = user || JSON.parse(window.sessionStorage.getItem("currentUser"));

  const options = currentUser ? [
    { value: "GAME", to: "/quiz" },
    { value: "TUTORIAL", to: "/tutorial" },
  ] : [];

  return (
    <header>
      <Logo className="app-logo" />
      <nav className="navbar">
        <button onClick={handleToggle}>
          {menuOpen ? (
            <CloseOutlined className="navbar-icon" />
          ) : (
            <MenuOutlined className="navbar-icon" />
          )}
        </button>
        <div className={`menuNav ${menuOpen ? "showMenu" : ""}`}>
          <NavLink
            exact
            to="/"
            className="navbar-item"
            activeClassName="navbar-selected"
            onClick={closeMenu}
          >
            Home
          </NavLink>
          {currentUser && <DropdownOptions options={options} className="navbar-item" />}
          <NavLink
            to="/leaderboard"
            className="navbar-item"
            activeClassName="navbar-selected"
            onClick={closeMenu}
          >
            Score board
          </NavLink>
          {/* {currentUser && (
            <NavLink
              to="/hskOcR"
              className="navbar-item"
              activeClassName="navbar-selected"
              onClick={closeMenu}
            >
              HSK OCR
            </NavLink>
          )} */}
          <NavLink
            to="/hskLookup"
            className="navbar-item" 
            activeClassName="navbar-selected"
            onClick={closeMenu}
          >
            HSK Text Analysis
          </NavLink>
          <NavLink
            to="/howtoplay"
            className="navbar-item"
            activeClassName="navbar-selected"
            onClick={closeMenu}
          >
            HELP
          </NavLink>
          {currentUser && (
            <NavLink
              to="/Profile"
              className="navbar-item"
              activeClassName="navbar-selected"
              onClick={closeMenu}
            >
              PROFILE
            </NavLink>
          )}

          {!currentUser ? (
            <NavLink
              to="/login"
              className="navbar-item"
              activeClassName="navbar-selected"
              onClick={closeMenu}
            >
              Log In
            </NavLink>
          ) : (
            <NavLink
              to="/"
              className="navbar-item"
              activeClassName="navbar-selected"
              onClick={() => {
                closeMenu();
                setUser(null);
                sessionStorage.clear();
                dispatch({
                  type: actions.SET_USER,
                  payload: { user: null },
                });
              }}
            >
              Log Out
            </NavLink>
          )}
        </div>
      </nav>
    </header>
  );
};

export default Navbar;

import { useState, useEffect, useContext } from "react";
// import "antd/dist/reset.css";
import { Form, Input, Button, Tabs, Modal } from "antd";
import { useHistory } from "react-router-dom";
import {
  PlusCircleOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  GoogleOutlined,
  LockOutlined,
} from "@ant-design/icons";
import "./Login.css";
import axios from "axios";
import { actions, GlobalContext } from "../../App";
import apiList from "../../lib/apiList";
import { GoogleLogin } from '@react-oauth/google';
import SelectHSKLevel from "../SelectHSKLevel/SelectHSKLevel";
const Login = (props) => {
  const [formStatus, setFormStatus] = useState("idle");
  const { dispatch } = useContext(GlobalContext);
  const history = useHistory();
  useEffect(() => {
    if (props.user) {
      history.push("/leaderboard");
    }
  }, [props.user, history]);
  const onFinish = async (values) => {
    try {
      //console.log({ values });
      setFormStatus("loading");

      const { data } = await axios.post(apiList.login, values);

      //console.log({ data });
      setFormStatus("success");
      props.setUser(data.user);

      dispatch({ type: actions.SET_USER, payload: { user: data.user._doc } });

      window.sessionStorage.setItem(
        "currentUser",
        JSON.stringify(data.user._doc)
      );
      history.push("/");
    } catch (error) {
      setFormStatus("failed");
    }

    // axios.get("http://localhost:5000").then((data) => {});

    // post data
    // fetch("https://localhost:5000/user/login", {
    //   method: "POST",
    //   headers: { "Content-Type": "application/json" },
    //   body: JSON.stringify(values),
    // })
    //   .then((res) => res.json())
    //   .then((data) => {
    //     if (data.auth) {
    //       setFormcStatus("success");
    //       props.setUser(data.user);
    //       try {
    //         window.sessionStorage.setItem(
    //           "currentUser",
    //           JSON.stringify(data.user)
    //         );
    //       } catch {
    //         // console.log("session storage error");
    //       }
    //       history.push("/");
    //     } else {
    //       setFormStatus("failed");
    //     }
    //   })
    //   .catch((err) => {
    //     setFormStatus("failed");
    //     // console.log(err);
    //   });
  };
  const buttonValues = {
    idle: {
      text: "Login In",
      loading: false,
      icon: <PlusCircleOutlined />,
    },
    loading: { text: "Logging In", loading: true, icon: null },
    success: {
      text: "You are now logged in",
      loading: false,
      icon: <CheckCircleOutlined />,
    },
    failed: {
      text: "Login Failed",
      loading: false,
      icon: <CloseCircleOutlined />,
    },
  };


  const PasswordLogin = () => {
    return (
      <Form name="login" onFinish={onFinish} layout="vertical" size="large">
        <h1>Log In</h1>
        <Form.Item
          label="Username"
          name="username"
          rules={[{ required: true, message: "Please input a username." }]}
        >
          <Input className="auth-input" placeholder="Enter your username" />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          rules={[{ required: true, message: "Please input a password." }]}
        >
          <Input.Password
            className="auth-input"
            placeholder="Enter your password"
          />
        </Form.Item>

        <Button
          htmlType="submit"
          type="primary"
          loading={buttonValues[formStatus].loading}
          icon={buttonValues[formStatus].icon}
          className="btn btn-primary"
        >
          {buttonValues[formStatus].text}
        </Button>
      </Form>
    );
  };

  const handleGoogleSuccess = async (credentialResponse) => {
    try {
      setFormStatus("loading");
      
      const SelectHSKLevelModal = (
        <SelectHSKLevel 
          googleCredential={credentialResponse.credential}
          dispatch={dispatch}
          history={history}
          setUser={props.setUser}
        />
      );
      
      Modal.info({
        title: (
          <div style={{
            fontWeight: '800',
            fontSize: '2.0rem',
            lineHeight: '1.2',
            background: 'linear-gradient(45deg, #e67329, #ff944d)',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
            textShadow: 'none',
            textAlign: 'center'
          }}>
            Select HSK Level
          </div>
        ),
        content: SelectHSKLevelModal,
        footer: null,
        closable: false,
        maskClosable: false,
        width: 500,
        className: 'hsk-level-modal',
        icon: null,
        centered: true
      });
      
    } catch (error) {
      console.error("Google login failed:", error);
      setFormStatus("failed");
    }
  };

  const GoogleLoginButton = () => {
    return (
      <div className="google-login-container">
        <GoogleLogin
          onSuccess={handleGoogleSuccess}
          onError={() => {
            // console.log('Login Failed');
            setFormStatus("failed");
          }}
          theme="filled_black"
          size="large"
          text="continue_with"
          useOneTap={false}
        />
      </div>
    );
  };

  const WeChatLogin = () => {
    return (
      <div className="wechat-login-container">
        <div className="qrcode-container">
          {/* <WechatOutlined className="wechat-icon" /> */}
          <div className="qrcode-wrapper">
            <div className="qrcode-placeholder">
              {/* Add your QR code component here */}
              <span>WeChat QR Code</span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="login-container">
      <Tabs
        defaultActiveKey="password"
        centered
        items={[
          {
            key: 'password',
            label: (
              <span>
                <LockOutlined /> Password Login
              </span>
            ),
            children: (
              <>
                <PasswordLogin />
                <p className="form-text">
                  Don't have an account yet?{" "}
                  <span 
                    onClick={() => history.push("/signup")} 
                    className="form-link"
                    style={{ cursor: 'pointer' }}
                  >
                    Sign up now
                  </span>
                </p>
              </>
            ),
          },
          {
            key: 'google',
            label: (
              <span>
                <GoogleOutlined /> Google Login
              </span>
            ),
            children: <GoogleLoginButton />,
          },
        ]}
      />
    </div>
  );
};

export default Login;

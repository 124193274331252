// import React, { useState } from "react";

// import Loading from "../Loading/Loading";
// import { Link } from "react-router-dom";

// import { ThumbDown } from "@mui/icons-material";

const TutorDetails = () => {
  // const [isLoading, setIsLoading] = useState(false);

  // return isLoading ? (
  //   <Loading></Loading>
  // ) : (
  //   <div className="tutor-container">
  //     <h1>Here are the Details of this tutor</h1>
  //   </div>
  // );
};
export default TutorDetails;

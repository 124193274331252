import React, { useState, useEffect } from "react";
import { Table, Badge, Dropdown, Menu, Button } from "antd";
import { useHistory } from "react-router-dom";
import UserAvatar from "../Avatar/Avatar.js";
import "./Scoreboard.css";
import Loading from "../Loading/Loading";
import axios from "axios";
import apiList from "../../lib/apiList.js";

const Podium = ({ score, place, color }) => {
  return (
    <div className={`podium-${place}`}>
      <Badge.Ribbon
        text={`${place}: ${score.username}`}
        offset={[0, 80]}
        color={color}
      >
        <UserAvatar
          user={{ id: score.userID }}
          size={place === "1st" ? 124 : 96}
        />
      </Badge.Ribbon>
    </div>
  );
};

const Scoreboard = (props) => {
  // console.log("scoreboard mounted");
  const [leaderboardData, setLeaderboardData] = useState([]);
  const [tableState, setTableState] = useState("loading");
  const history = useHistory();
  const [selectedMode, setSelectedMode] = useState("global");
  useEffect(() => {
    if (!props.user) {
      history.push("/login");
    }
  }, []);

  const urls = {
    global: apiList.score,
    user: `${apiList.score}/${props.user?.id}`,
  };
  const modeText = { global: "Global Scores", user: "Your Scores" };

  useEffect(() => {
    setTableState("loading");
    const url = urls[selectedMode];
    
    (async () => {
      try {
        const { data } = await axios.get(url);
        // console.log('Raw data from server:', data);
        // console.log('Current user props:', props.user);
        
        // Get the user's HSK level from the correct location
        const userHSKLevel = props.user?._doc?.hskLevel || props.user?.hskLevel;
        // console.log('User HSK Level:', userHSKLevel);
        
        let processedData;
        
        if (selectedMode === 'global') {
          try {
            // First get all users
            const { data: userData } = await axios.get(apiList.user);
            const userInfo = {};
            if (userData.docs && Array.isArray(userData.docs)) {
              userData.docs.forEach(user => {
                userInfo[user._id] = {
                  hskLevel: user.hskLevel || 'HSK1',
                  username: user.username
                };
              });
            }
            console.log('Total users:', Object.keys(userInfo).length);

            // Get all scores for all users
            const allScoresPromises = Object.keys(userInfo).map(userId => 
              axios.get(`${apiList.score}/${userId}`)
                .then(response => response.data)
                .catch(error => {
                  console.error(`Error fetching scores for user ${userId}:`, error);
                  return [];
                })
            );

            const allUserScores = await Promise.all(allScoresPromises);
            const allScores = allUserScores.flat();

            console.log('Total scores fetched:', allScores.length);

            // Get highest score for each user
            const userHighestScores = {};
            allScores.forEach(score => {
              const userId = score.userID || score.user_id;
              const currentScore = Number(score.score || 0);
              const user = userInfo[userId];
              
              if (user && (!userHighestScores[userId] || userHighestScores[userId].score < currentScore)) {
                userHighestScores[userId] = {
                  userID: userId,
                  username: user.username,
                  score: currentScore,
                  level: user.hskLevel,
                  date: score.date,
                  key: `${userId}-best`
                };
              }
            });

            // Group users by HSK level
            const scoresByLevel = {
              'HSK1': [], 'HSK2': [], 'HSK3': [], 
              'HSK4': [], 'HSK5': [], 'HSK6': []
            };

            Object.values(userHighestScores).forEach(score => {
              if (score.level && scoresByLevel[score.level]) {
                scoresByLevel[score.level].push(score);
              }
            });

            // Get top 5 from each level and combine
            processedData = Object.entries(scoresByLevel).flatMap(([level, scores]) => {
              const topFive = scores
                .sort((a, b) => b.score - a.score)
                .slice(0, 5);
              
              console.log(`Top 5 users for ${level}:`, 
                topFive.map(s => `${s.username}: ${s.score}`)
              );
              return topFive;
            }).filter(score => score.score > 0); // Only include scores greater than 0

            // Get podium users (top 3 overall)
            const podiumUsers = Object.values(userHighestScores)
              .sort((a, b) => b.score - a.score)
              .slice(0, 3);
            
            console.log('Podium users:', 
              podiumUsers.map(u => `${u.username}: ${u.score}`)
            );

            console.log('Total users displayed:', processedData.length);

          } catch (error) {
            console.error("Error processing scores:", error);
            processedData = [];
          }
        } else {
          // For personal scores, keep all scores
          processedData = data.map(score => ({
            ...score,
            userID: score.userID || score.user_id || score._id,
            username: score.username || score.user?.username,
            // For personal scores, always use the current user's HSK level
            level: userHSKLevel || 'N/A'
          }));
        }

        // console.log('Processed data before sorting:', processedData);

        // Sort by score in descending order
        processedData.sort((a, b) => b.score - a.score);
        
        const finalData = processedData.map((score, index) => ({
          ...score,
          date: new Date(score.date).toLocaleDateString(["ban", "id"]),
          index: index + 1,
          key: score._id || score.id,
          level: score.level || userHSKLevel || 'N/A'
        }));

        // console.log('Final leaderboard data:', finalData);
        setLeaderboardData(finalData);
        setTableState("loaded");
      } catch (error) {
        console.error("Unable to fetch scores from server:", error);
      }
    })();
  }, [selectedMode, props.user?._id, props.user?._doc?.hskLevel]);

  const columns = [
    { title: "#", dataIndex: "index", key: "index" },
    {
      title: "User",
      dataIndex: "username",
      key: "username",
    },
    {
      title: "Level",
      dataIndex: "level",
      key: "level",
      render: (level) => level || 'N/A'
    },
    {
      title: "Score",
      dataIndex: "score",
      key: "score",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
  ];
  const places = ["1st", "2nd", "3rd"];
  const medals = ["#AF9500", "#B4B4B4", "#6A3805"];

  const handleMenuClick = ({ key }) => {
    setSelectedMode(key);
  };

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="global">Global Scores</Menu.Item>
      <Menu.Item key="user">Your Scores</Menu.Item>
    </Menu>
  );

  return (
    <div className="scoreboard-container">
      <Dropdown
        overlay={menu}
        placement="bottomRight"
        trigger={["click"]}
        arrow
      >
        <Button>{modeText[selectedMode]}</Button>
      </Dropdown>
      {selectedMode === "global" ? (
        <div className="podium">
          {leaderboardData?.slice(0, 3).map((score, index) => {
            const place = places[index];
            return (
              <Podium
                key={place}
                score={score}
                place={places[index]}
                color={medals[index]}
              />
            );
          })}
        </div>
      ) : null}
      <div className="tableScore">
        {tableState === "loaded" ? (
          <Table
            dataSource={leaderboardData}
            columns={columns}
            pagination={false}
          />
        ) : (
          <Loading />
        )}
      </div>
    </div>
  );
};

export default Scoreboard;

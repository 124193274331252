import React, { useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import "./Home.css";
import { ButtonOptions } from "../ButtonOptions/ButtonOptions";

const Home = ({ user }) => {
  const history = useHistory();
  const buttonsRef = useRef(null);

  useEffect(() => {
    if (!user) {
      // Only set user from session storage, don't redirect
      const sessionUser = JSON.parse(window.sessionStorage.getItem("currentUser"));
      // Handle setting the user here if needed
    }
  }, [user]);

  const scrollToButtons = () => {
    buttonsRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
  };

  return (
    <div className="Home">
      <div className="main-content">
        <div className="hometitle-container">
          <div className="title-wrapper">
            <h1 className="hometitle">Win HSK: Your Road to Success!</h1>
            <div className="title-underline"></div>
          </div>
          <p className="homesubtitle">
            Have you tried countless resources but didn't find them fun? 
            It's time to make learning fun! Start your HSK journey 
            with interactive games and engaging tutorials!
          </p>
          
          <div className="scroll-arrow" onClick={scrollToButtons}>
            <div className="arrow"></div>
          </div>
        </div>

        <div className="home-buttonsContainer" ref={buttonsRef}>
          {!user ? (
            <div className="buttoncontainer1">
              <button
                onClick={() => history.push("/login")}
                className="btn homebtn getstarted"
              >
                GET STARTED
              </button>
            </div>
          ) : (
            <ButtonOptions />
          )}
        </div>

        <div className="feature-cards">
          <div className="feature-card">
            <i className="fas fa-graduation-cap"></i>
            <h3>Interactive Learning</h3>
            <p>Follow tutorials and complete exercises in Reading, Listening, and Writing to enhance your skills</p>
          </div>

          <div className="feature-card">
            <i className="fas fa-gamepad"></i>
            <h3>Game Mode</h3>
            <p>Play timed challenges with bonus points and extra time for correct answers</p>
          </div>

          <div className="feature-card">
            <i className="fas fa-trophy"></i>
            <h3>Global Scoreboard</h3>
            <p>Compete with other learners and track your ranking in our global leaderboard</p>
          </div>

          <div className="feature-card">
            <i className="fas fa-search"></i>
            <h3>HSK Text Analysis</h3>
            <p>Analyze Chinese text with color-coded HSK levels and translations</p>
          </div>

          <div className="feature-card">
            <i className="fas fa-book"></i>
            <h3>Comprehensive Learning</h3>
            <p>Access detailed explanations and translations for every question</p>
          </div>

          <div className="feature-card">
            <i className="fas fa-chart-line"></i>
            <h3>Track Progress</h3>
            <p>Monitor your learning journey with detailed progress tracking</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
